import Vue from 'vue';

/**
 * 价格分转换成元
 * @param {*} num
 */
Vue.prototype.fenToYuan = num => {
    if (typeof num !== 'number' || isNaN(num)) {
        return null;
    }
    if (num <= 0) {
        return 0;
    }
    // eslint-disable-next-line no-magic-numbers
    return (num * .01).toFixed(2);
};
